import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Split Jerk 4-4-4-4\\@80% 1RM`}</p>
    <p>{`Band Straight Arm Pulldowns 10-10-10-10`}</p>
    <p>{`then,`}</p>
    <p>{`50 Calorie Row Buy In,`}</p>
    <p>{`21-15-9 reps of:`}</p>
    <p>{`S2OH (135/95)`}</p>
    <p>{`T2B`}</p>
    <p>{`50 Calorie Row Cash Out`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 6 is coming September 15th.  We will accept late
registrations until the end of the day today but you will not get your
shirts on the 15th.  If you’d like to compete but don’t have a team we
have a signup sheet by the white board. You can also email us to get on
a team. If you’d like to help judge please add your name to the sheet.
 We still need several more judges.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts on 9/23.  For more info email Eric
at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      